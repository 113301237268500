import * as Sentry from '@sentry/browser';

export default {
  state: {
    defaultTenant: null,
    tenant: null,
    tenants: [],
  },
  mutations: {
    RESET_TENANT(state) {
      state.defaultTenant = null;
      state.tenant = null;
      state.tenants = [];
    },
    SET_DEFAULT_TENANT(state, tenant) {
      state.defaultTenant = tenant;
    },
    SET_TENANT(state, tenant) {
      state.tenant = tenant;
      if (SENTRY_ENABLED) {
        Sentry.configureScope((scope) => {
          const userInfo = scope._user;
          userInfo.tenantId = tenant.id;
          userInfo.tenantName = tenant.name;
          Sentry.setUser(userInfo);
        });
      }
    },
    SET_TENANTS(state, tenants) {
      tenants.sort((a, b) => {
        if (a.display_name < b.display_name) { return -1; }
        if (a.display_name > b.display_name) { return 1; }
        return 0;
      });
      state.tenants = tenants;
    },
  },
  actions: {
    resetTenant({ commit }) {
      commit('RESET_TENANT');
    },
    setDefaultTenant({ commit }, tenant) {
      commit('SET_DEFAULT_TENANT', tenant);
    },
    setTenant({ commit }, tenant) {
      commit('SET_TENANT', tenant);
    },
    setTenants({ commit }, tenants) {
      commit('SET_TENANTS', tenants);
    },
  },
  getters: {
    defaultTenant: (state) => state.defaultTenant,
    domainTenant: (state, getters) => {
      let domainTenant;
      if (state.tenants.length > 0 && getters.user) {
        const userDomain = getters.user.email.split('@')[1];
        domainTenant = state.tenants.find((t) => t.domain && t.domain.includes(userDomain));
      }
      return domainTenant;
    },
    tenant: (state) => state.tenant,
    tenants: (state) => state.tenants,
  },
};
