<template>
  <div id="forgot-password-form">
    <div v-if="!submitted">
      <p class="text-left">
        Please confirm your email address.
      </p>
      <div class="mt-5">
        <v-text-field
          v-model="email"
          name="email"
          label="Email"
          @keyup="(event) => {
            if (event.key === 'Enter') {
              submit();
            }
          }"/>
      </div>
      <div>
        <v-btn
          :disabled="!email || email.length === 0 || !!loading"
          :loading="loading"
          color="secondary"
          class="float-right next text-white"
          @click="submit">
          Confirm
        </v-btn>
        <v-btn
          default
          class="float-left back text-black"
          @click="() => { switchPage('email') }">
          Back
        </v-btn>
      </div>
    </div>
    <div v-if="submitted">
      <p class="text-left">
        We have received your request. You should receive an email shortly with a link to reset your password.

        If you do not receive an email, please try again or contact your account manager for assistance.
      </p>
      <div>
        <v-btn
          class="back text-white"
          color="secondary"
          @click="() => { switchPage('email') }">
          Back to Login
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { EventLog } from '@/lib/event-log';

export default {
  name: 'ForgotPassword',
  props: {
    passedEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: null,
      submitted: false,
      email: this.passedEmail,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      const forgotData = {
        email: this.email,
      };

      const loggingData = new EventLog({
        event: 'account.password.request_reset',
        email: this.email,
      });
      this.$services.users.postTrackingLog(loggingData);

      this.$services.users.postForgotPassword(forgotData).then((json) => {
        // to avoid abuse of this feature for email enumeration, we'll
        // simply always indicate success.
        this.submitted = true;
      }).catch((error) => {
        // TODO: Have the frontend validate this first.
        const displayedError = (error === 'Did not pass schema validation') ? 'Invalid email.' : error;
        this.$notify(displayedError);
        const failLog = new EventLog({
          event: 'account.password.fail_request_reset',
          email: this.email,
        });
        this.$services.users.postTrackingLog(failLog);
      }).finally(() => {
        this.loading = null;
      });
    },
    switchPage(page) {
      this.$emit('switchPage', page);
    },
  },
};
</script>
