<template>
  <div class="sign-in-container">
    <div>
      <v-dialog
        v-model="authDialog"
        max-width="350">
        <v-card>
          <v-card-title class="text-h5">
            2-Step Verification
          </v-card-title>
          <v-card-text>
            Please enter the 6-digit verification code sent to your phone.
            <v-text-field
              v-model="token"
              :disabled="verifyLoading"
              label="Code"
              autofocus
              @keyup="(event) => {
                if (event.key === 'Enter') {
                  verifyCode();
                }
              }"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn
              :disabled="verifyLoading"
              variant="flat"
              @click="authDialog = false">
              Cancel
            </v-btn>
            <v-btn
              :loading="verifyLoading"
              :disabled="verifyLoading"
              color="secondary"
              variant="flat"
              @click="verifyCode">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="re-fill-layout">
      <div
        class="disable-flex-grow"
        justify-center>
        <img
          class="bainbridge-name-horiz mx-4"
          src="~@/assets/images/BH-Horizontal.svg">
      </div>
      <div
        class="disable-flex-grow"
        justify-center>
        <v-card class="mt-6 input-container">
          <v-card-title class="text-white bg-primary pt-1">
            <div>
              <p class="med-os-tag">
                Med O.S.
              </p>
              {{ state }}
            </div>
          </v-card-title>
          <v-card-text class="pa-6">
            <transition
              name="slide-x-transition"
              mode="out-in">
              <div>
                <v-skeleton-loader
                  v-if="!loginComplete"
                  :loading="loading"
                  height="94"
                  type="list-item-two-line"/>
                <EnterEmail
                  v-if="page === 'email' && loginComplete"
                  :passed-identity="email"
                  :loading="loading"
                  @ssoLogin="ssoLogin"
                  @setIdentity="setIdentity"
                  @switchPage="switchPage"/>
                <EnterPassword
                  v-else-if="page === 'password' && loginComplete"
                  :email="email"
                  :loading="loading"
                  @login="passwordLogin"
                  @switchPage="switchPage"/>
                <ForgotPassword
                  v-else-if="page === 'forgot' && loginComplete"
                  :passed-email="email"
                  @switchPage="switchPage"/>
              </div>
            </transition>
            <div
              id="recaptcha-container"
              style="display: none"/>
            <div class="clearfix"/>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import {
  GoogleAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  SAMLAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';

import { EventLog } from '@/lib/event-log';
import { GOOGLE_IDENTITY_PROVIDER } from '@/lib/identity-methods';

import EnterEmail from './signin/EnterEmail.vue';
import EnterPassword from './signin/EnterPassword.vue';
import ForgotPassword from './signin/ForgotPassword.vue';

export default {
  name: 'LoginPortal',
  components: {
    EnterEmail,
    EnterPassword,
    ForgotPassword,
  },
  data() {
    return {
      page: 'email',
      loading: false,
      verifyLoading: false,
      authDialog: false,
      email: null,
      identityTenant: null,
      localIdentityProvider: null,
      authentication: null,
      expiredAccount: false,
      resolver: null,
      verificationId: null,
      token: null,
      recaptchaVerifier: null,
      state: 'Login',
    };
  },
  computed: {
    ...mapGetters([
      'identityProvider',
      'loginComplete',
      'multiFactorRequired',
      'user',
    ]),
  },
  watch: {
    authDialog: {
      handler() {
        if (!this.authDialog) {
          this.token = null;
          this.loading = false;
          this.verifyLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
    loginComplete: {
      handler() {
        if (this.loginComplete && this.user) {
          this.redirectToPath();
        }
      },
    },
  },
  mounted() {
    if (this.user) {
      this.redirectToPath();
    }
  },
  methods: {
    switchPage(page) {
      this.page = page;
      if (page === 'forgot') {
        this.state = 'Request Password Reset';
      } else {
        this.state = 'Login';
      }
    },
    setIdentity(identityData) {
      this.email = identityData.email;
      this.identityTenant = identityData.tenant;
      this.localIdentityProvider = identityData.provider;
      this.setIdentityProvider(this.localIdentityProvider);
      if (identityData.cred_expires && moment.utc(identityData.cred_expires) < moment.utc()) {
        this.expiredAccount = true;
      } else {
        this.expiredAccount = false;
      }
      this.page = 'password';
    },
    emitFailLog(error, message) {
      const failLog = new EventLog({
        event: 'account.fail_login',
        email: this.email,
        identityTenant: this.identityTenant,
        identityProvider: this.localIdentityProvider,
        error,
      });
      this.$services.users.postTrackingLog(failLog);
      this.$notify(message);
    },
    emitSuccessLog() {
      const successLog = new EventLog({
        event: 'account.log_in',
        email: this.email,
        identityTenant: this.identityTenant,
        identityProvider: this.localIdentityProvider,
      });
      this.$services.users.postTrackingLog(successLog);
    },
    passwordLogin(authentication) {
      this.loading = true;
      if (this.expiredAccount) {
        this.emitFailLog('expired_password', 'Your password expired. Please click "Forgot Password?" to reset it.');
        this.loading = false;
      } else {
        this.authentication = authentication;
        if (!this.recaptchaVerifier) {
          // Initialize reCAPTCHA verifier for multi-factor authentication
          this.recaptchaVerifier = new RecaptchaVerifier(this.$firebaseAuth, 'recaptcha-container', {
            size: 'invisible',
          });
        }
        signInWithEmailAndPassword(this.$firebaseAuth, this.email, this.authentication).then((userInfo) => {
          this.setAccessToken(userInfo.user._lat);
          this.login().catch((error) => {
            this.emitFailLog(error.message, 'Invalid email or password.');
          })
            .finally(() => {
              this.loading = false;
            });
        }).catch((tokenMessage) => {
          if (tokenMessage.code === 'auth/multi-factor-auth-required') {
            this.resolver = tokenMessage.resolver;
            if (this.resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              const phoneInfoOptions = {
                multiFactorHint: this.resolver.hints[0],
                session: this.resolver.session,
              };
              const phoneAuthProvider = new PhoneAuthProvider(this.$firebaseAuth);
              // Send SMS verification code
              phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier).then((verificationId) => {
                this.verificationId = verificationId;
                // Ask user for the SMS verification code to continue login
                this.authDialog = true;
              }).catch((error) => {
                this.emitFailLog(error.message, 'Verification failed.');
                this.loading = false;
              });
            } else {
              this.emitFailLog(tokenMessage.message, 'Invalid email or password.');
              this.loading = false;
            }
          } else if (tokenMessage.code === 'auth/too-many-requests') {
            this.emitFailLog(tokenMessage.message, 'Too many failed login attempts. Please try again later.');
            this.loading = false;
          } else {
            this.emitFailLog(tokenMessage.message, 'Invalid email or password.');
            this.loading = false;
          }
        });
      }
    },
    verifyCode() {
      this.verifyLoading = true;
      const cred = PhoneAuthProvider.credential(this.verificationId, this.token);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      this.resolver.resolveSignIn(multiFactorAssertion).then((userInfo) => {
        this.setAccessToken(userInfo.user._lat);
        this.login().catch((error) => {
          this.emitFailLog(error.message, 'Login failed.');
        })
          .finally(() => {
            this.authDialog = false;
          });
      }).catch((error) => {
        this.emitFailLog(error.message, 'Verification failed.');
        this.authDialog = false;
      });
    },
    redirectToPath() {
      if (this.multiFactorRequired) {
        this.$router.push({ name: 'UserProfile' });
      } else if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push({ name: 'LandingPage' });
      }
    },
    requiresPopup() {
      if (this.detectBrowser.isSafari || this.detectBrowser.isFirefox) {
        return true;
      } else {
        return false;
      }
    },
    ssoLogin(identityData) {
      this.loading = true;
      this.email = identityData.email;
      this.identityTenant = identityData.tenant;
      this.localIdentityProvider = identityData.provider;
      let provider;
      if (this.localIdentityProvider === GOOGLE_IDENTITY_PROVIDER) {
        provider = new GoogleAuthProvider(this.$firebaseAuth);
        provider.setCustomParameters({
          prompt: 'consent',
        });
      } else {
        provider = new SAMLAuthProvider(this.$firebaseAuth, this.localIdentityProvider);
      }
      this.$firebaseAuth.tenantId = this.identityTenant;
      if (this.requiresPopup()) {
        signInWithPopup(this.$firebaseAuth, provider).catch((error) => {
          this.emitFailLog(error.message, 'Login with this browser requires popups. Your browser blocked the popup. Please enable popups for this site.');
          this.loading = false;
        });
      } else {
        signInWithRedirect(this.$firebaseAuth, provider).catch((error) => {
          this.emitFailLog(error.message, 'Login failed.');
          this.loading = false;
        });
      }
    },
    ...mapActions([
      'login',
      'setAccessToken',
      'setIdentityProvider',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.sign-in-container {
  .re-fill-layout {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
  }

  .disable-flex-grow {
    flex-grow: 0;
  }

  .bainbridge-name-horiz {
    width: 600px;
  }

  .input-container {
    width: 580px;
  }
}

.med-os-tag {
  font-size: 18px;
  margin: 0;
}
</style>
