import { Request } from '@/lib/http';

const CONFIG_VERSION = 'v2';
const BASE_URL = '/api/config/v2';
const CONFIG_KEY = 'config';
const CONFIG_LAST_SET_KEY = 'configLastSet'; // deprecated

export default {
  getConfig() {
    return new Promise((resolve, reject) => {
      let config = null;

      const configJson = localStorage.getItem(CONFIG_KEY);
      if (configJson) {
        try {
          config = JSON.parse(configJson);

          if (config.version) {
            if (config.version < CONFIG_VERSION) {
              throw new Error(`version outdated: ${config.version}`);
            }

            if (!this.isEffective(config)) {
              const exp = new Date(config.exp * 1000);
              throw new Error(`expired: ${exp.toISOString()} (now: ${new Date().toISOString()})`);
            }
          } else {
            throw new Error('missing version');
          }
        } catch (err) {
          /* eslint-disable no-console */
          // console logging this as its likely sentry isn't yet enabled
          // and we'd like to know about this issue if possible.
          console.error(`stored config: ${err}`, err.trace);
          /* eslint-enable no-console */
          config = null;
          this.clearConfig();
        }
      }

      if (config) {
        resolve(config);
      } else {
        resolve(this.fetchConfig().then((result) => {
          this.storeConfig(result);
          return result;
        }));
      }
    });
  },

  fetchConfig() {
    const request = new Request(`${BASE_URL}`, { withoutAuth: true, withoutClaims: true });
    return request.send().then((result) => result.config);
  },

  isEffective(config) {
    if (config) {
      if (config.version && config.version >= CONFIG_VERSION && config.exp) {
        const now = new Date();
        const exp = new Date(config.exp * 1000);
        if (exp >= now) {
          return true;
        }
      }
    }

    return false;
  },

  clearConfig() {
    localStorage.removeItem(CONFIG_KEY);
    localStorage.removeItem(CONFIG_LAST_SET_KEY);
  },

  storeConfig(config) {
    localStorage.setItem(CONFIG_KEY, JSON.stringify(config));
  },
};
